<template>
  <div>
    <div data-vl-widget="embedForm"></div>
    <div data-vl-widget="rewardStats"></div>
  </div>
</template>
<script>
import { loadingService } from "../../services/loading.service";
export default {
  data() {
    return {
      email: localStorage.getItem("user_email"),
      campaign: "",
    };
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    try {
      loadingService.setloader(true);
      this.campaign = window.VL.load("kgxdyj1Dj50aIpP4JhneyVmvQCA", {
        autoLoadWidgets: !0,
      });
      this.campaign.identify(
        {
          firstname: "",
          lastname: "",
          email: this.email,
        },
        (res) => {
          res;
          loadingService.setloader(false);
        }
      );
    } catch (error) {
      location.reload();
      throw error;
    }
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
};
</script>
